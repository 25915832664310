:root {
    --color-primary: black;
    --color-primary-light: black;
    --color-primary-dark: black;
    --best-color-grey: #4a4a4a;

    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;
    
    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

    --line: 1px solid var(--color-grey-light-2);
}

$bp-largest: 75em;     // 1200px
$bp-large: 68.75em;    // 1100px
$bp-medium: 56.25em;   // 900px
$bp-small: 37.5em;     // 600px
$bp-smallest: 31.25em; // 500px

* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

    @media only screen and (max-width: $bp-large) {
        font-size: 50%;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: var(--color-grey-dark-2);
    background-image: linear-gradient(to right bottom, var(--color-primary-light), var(--color-primary-dark));
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 100vh;
    padding-bottom: .01rem;

    .layer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        //background-image: linear-gradient(
        //                to bottom,
        //                rgba(0, 0, 0, 0.9) 100%,
        //                rgba(0, 0, 0, 0.8) 50%)
    }
}

.pdfRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background-color: red !important;
    width: 100%;
}