.add-ref {
  //margin-left: 1rem;
  width: 93%;
}

.add-ref-image {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  align-self: center;
  padding: 2rem;
  //max-height: 25rem;
  //max-width: 25rem;
  height: 28rem;
}

.detail-loader {
  display: flex;
  justify-content: center;
}

.img-err {
  height: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .header-err {
    width: 23rem;
    text-align: center !important;
  }
}

.ui.pointing.label, .ui[class*="pointing above"].label {
  font-size: 1.1rem;
}

.field {
  display: flex;
  flex-direction: column;
}

.pointing {
  width: fit-content;
  align-self: center;
}