.container {
    max-width: 120rem;
    margin: 8rem auto;
    background-color: var(--color-grey-light-1);
    box-shadow: var(--shadow-dark);
    min-height: 50rem;
    //z-index: 99;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%,-50%);
    //width: 100%;

    @media only screen and (max-width: $bp-largest) {
        margin: 0;
        max-width: 100%;
        width: 100%;
    }
}

.home-header {
    font-size: 1.4rem;
    height: 7rem;
    background-color: #fff;
    border-bottom: var(--line);

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $bp-smallest) {
        flex-wrap: wrap;
        align-content: space-around;
        height: 11rem;
    }
}

.content {
    display: flex;

    @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
    }
}

.sidebar {
    background-color: var(--color-grey-dark-1);
    min-height: 100%;
    flex: 0 0 25%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hotel-view {
    background-color: #fff;

    flex: 1;
}

.detail {
    font-size: 1.4rem;
    display: flex;
    //padding: 4.5rem;
    //background-color: var(--color-grey-light-1);
    border-bottom: var(--line);
        min-height: 40rem;
    //text-align: center;
    @media only screen and (max-width: $bp-medium) {
        padding: 3rem;
    }

    @media only screen and (max-width: $bp-small) {
        flex-direction: column;
    }
}
