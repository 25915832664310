.slider-img {
  width: 5rem;
  height: 4rem;
}

.slider-img-td {
  //display: flex;
  //align-items: center;
  //justify-content: center;
}

.slider-image-update {
  margin-top: 3rem;
  width: 20rem !important;
  height: 20rem !important;
  align-self: flex-start !important;
}

.header {
  width: 50rem;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.detail-loader {
  .loader-spinner > div {
    background-color: var(--best-color-grey);
  }
}

.danger-text {
  text-align: left !important;
  background-color: #fff6f6;
  border: none;
}

.add-image {
  height: 20rem;
}

.empty-img {
  height: 20rem;
}

.content__text--btn {
  transition: all .1s;
  margin-top: 3rem;

  & a {
    text-decoration: none;
    padding: .7rem 1.5rem;
    font-size: 1.5rem;
    color: white;
    background-color: black;
    position: relative;
    display: inline-block;
    width: 22rem;
    overflow: hidden;
    z-index: 1;

    svg {
      margin-bottom: -.1rem;
    }


    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 3%;
      height: 100%;
      background-image: linear-gradient(to right, var(--color-primary-dark), var(--color-primary));
      left: 0;
      transition: .8s;
      z-index: -1;
    }

    &:hover:before {
      width: 180%;
    }
  }


}

.box {
  width: 50vw;
  height: 50vh;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-690/star.svg);
  background-size: 20px;
  background-repeat: round;
  overflow: hidden;
  resize: both;
  position: relative;
  min-width: 150px;
  min-height: 60px;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: white;
  }


  display: grid;
  place-items: center;

  span {
    position: relative;
  }
}

.slider-image {
  width: 70rem;
  height: 35rem;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  align-self: center;
}

.list {
  flex-direction: column !important;
}

.slider-img__block {
  display: flex;
  flex-direction: column;
}

.form-btn {
  width: 25rem;
}

.btn-block {
  margin-top: 3rem !important;
  text-align: center;
  width: 100%;
}

.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
  //margin: 20% auto;
  margin-top: 2rem;
  cursor: pointer;
  margin-bottom: 2rem;

  &:hover {
    .button-wrapper span.label {
    }
  }
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  //background: #00bfff;
  border: 1px solid var(--color-grey-dark-3);
  cursor: pointer;
  color: var(--best-color-grey);
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.add-button-wrapper {
  margin-bottom: 5rem;
}

.detail-content {
  margin: 5rem 0;
  width: 78%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-bigContent {
  margin: 5rem 0;
  width: 98%;
}

.formGroup {
  text-align: left;
  //margin-right: 2rem !important;
  label {
    font-size: 1.8rem;

  }
}