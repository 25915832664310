.sub-column {
  width: 2rem;
}
.overview__heading {
  svg {
    color : var(--color-primary);
    cursor: pointer;
    &:hover {
      color : var(--color-primary-dark);

    }
  }
}
.content__text--btn {
  transition: all .1s;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-cat-text {
  textarea {
    font-size: 1.7rem !important;
  }
}

.add-cat-img {
  height: 28rem !important;
}

.sub-column {
  width: 2rem;

}
.des-column {
  width: 28rem !important;
}

.des-column-min {
  width: 16rem;
}


.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 15rem;
  border: var(--line);
  height: 14rem;
}
.pdf-image {
  margin-left: -8rem;
  //height: 16rem;
}